export default {
  // 登录
  login: '/uc/login/account',
  //验证码登录
  loginverifycode: '/uc/login/phone/verifycode',
  //验证码
  verifycode: '/uc/login/send/phone/verifycode',
  //注册
  register: '/uc/register',
  //我的机构
  myorg: '/course/supply/org/myorg',
  //切换学院
  orgChange: '/uc/identify/org/change',
  //权限查询
  userAuthQuery: '/course/manage/auth/query',
  //实名认证
  userRealAuth: '/course/manage/auth/real/name/auth',
  //获取用户数据
  getUser: '/uc/identify/user/baseinfo',
  //提交用户数据
  getUserData: '/uc/update/info',
  //更换手机验证码
  ucVerifyCode: '/uc/identify/reset/phone/send/verify/code',
  //更换手机接口
  ucChangePhone: '/uc/identify/reset/phone',
  //更换密码验证码
  passVerifyCode: '/uc/resetpassword/send/phone/verifycode',
  //更换密码
  updatePassword: '/uc/resetpassword/phone',
  //投诉建议
  suggest: '/course/identify/suggest/create',
}
