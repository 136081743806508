export default {
  searchCollege: '/course/supply/org/tenant',
  editCollege: '/course/supply/org/update',
  adminList: '/course/supply/admin/page',
  adminAdd: '/course/supply/admin/add',
  adminAddTable: '/course/supply/admin/batch/add/from/student',
  allRole: '/security/identify/role/query/page',
  addRole: '/security/identify/role/create',
  editRole: '/security/identify/role/edit',
  batchDelRole: '/security/identify/role/delete/batch',
  roleJurisdiction: '/security/identify/role/permission/query',
  sureJurisdiction: '/security/identify/role/permission/create',
  userAdmin: '/security/identify/user/role/create/batch',
  menu: '/security/identify/menu/query/tree',
  userMenu: '/course/manage/menu/query/user/tree',
  orderBank: '/order/identify/bankCard/bdOrBd',
  accountsMoney: '/order/identify/amount/show',
  bankNew: '/order/identify/bankCard/show',
  adminCode: '/course/supply/admin/change/supper/send/phone/verifycode',
  changeAdmin: '/course/supply/admin/change/supper',
  accountsCode: '/order/identify/bankCard/code',
  searchSuperAdmin: '/course/supply/auth/query', //查询是否是超级管理员
  adminDelete: '/course/supply/admin/batch/delete',

  searchNoticeList: '/course/manager/announcement/query/list',
  noticeListDelete: '/course/manager/announcement/delete',
  noticeListEdit: '/course/manager/announcement/edit',
  noticeAdd: '/course/manager/announcement/create',
  bannerList: '/course/manager/adbanner/query/list',
  bannerType: '/course/manager/adbanner/query/marks',
  bannerEditSearch: '/course/manager/adbanner/query',
  bannerListEdit: '/course/manager/adbanner/edit',
  bannerListDel: '/course/manager/adbanner/delete',
  bannerAdd: '/course/manager/adbanner/create',
  seat: '/course/manage/org/current/member',
  charge: '/course/manage/org/version/items',
  versionUpdate: '/course/manage/org/version/update',
  enterpriseRecharge: '/order/identify/orgOrder/enterprise/invest',
}
