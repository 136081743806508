/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-12-31 09:51:59
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-01-14 08:45:14
 */
import Layout from '@/layout'
export default [
  {
    path: '/certificate',
    name: 'Certificate',
    component: Layout,
    redirect: '/certificate/credentials',
    meta: { title: '证书管理', icon: 'el-icon-document' },
    children: [
      {
        path: 'credentials',
        name: 'Credentials',
        component: () => import('@/views/certificateManage/credentials/index'),
        meta: { title: '证书管理' },
      },
      {
        path: 'add',
        name: 'CredentialsAdd',
        component: () => import('@/views/certificateManage/credentials/add'),
        meta: { title: '添加证书项目' },
      },
      {
        path: 'detail',
        name: 'CredentialsDetail',
        component: () => import('@/views/certificateManage/credentials/detail'),
        meta: { title: '证书详情' },
      },
      {
        path: 'signUp',
        name: 'SignUp',
        component: () => import('@/views/certificateManage/signUp/index'),
        meta: { title: '报名管理' },
      },
      {
        path: 'data',
        name: 'Data',
        component: () => import('@/views/certificateManage/signUp/data'),
        meta: { title: '报名资料' },
      },
      {
        path: 'uploadData',
        name: 'UploadData',
        component: () => import('@/views/certificateManage/signUp/uploadData'),
        meta: { title: '上传资料' },
      },
      {
        path: 'importOrExport',
        name: 'ImportOrExport',
        component: () => import('@/views/certificateManage/signUp/importOrExport'),
        meta: { title: '导入成绩和证书' },
      },
      {
        path: 'trainingManage',
        name: 'TrainingManage',
        component: () => import('@/views/certificateManage/trainingManage'),
        meta: { title: '证书培训管理' },
      },
      {
        path: 'trainingManage/add',
        name: 'TrainingManageAdd',
        component: () => import('@/views/certificateManage/trainingManage/add'),
        meta: { title: '编辑证书培训' },
      },
      {
        path: 'trainingManage/notice',
        name: 'TrainingManageNotice',
        component: () => import('@/views/certificateManage/trainingManage/notice'),
        meta: { title: '公告' },
      },
      {
        path: 'trainingManage/notice/add',
        name: 'TrainingManageNoticeAdd',
        component: () => import('@/views/certificateManage/trainingManage/notice/add'),
        meta: { title: '新增公告' },
      },
      {
        path: 'trainingManage/notice/:id',
        name: 'TrainingManageNoticeEdit',
        component: () => import('@/views/certificateManage/trainingManage/notice/add'),
        meta: { title: '编辑公告' },
      },
      {
        path: 'trainingManage/:classCertId',
        name: 'TrainingManageEdit',
        component: () => import('@/views/certificateManage/trainingManage/add'),
        meta: { title: '编辑证书培训' },
      },
      {
        path: 'mailing',
        name: 'mailing',
        component: () => import('@/views/certificateManage/mailing/index'),
        meta: { title: '证书邮寄管理' },
      },
    ],
  },
]
