/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-09-28 09:35:20
 */
import Layout from '@/layout'

export default [
  {
    path: '/college',
    name: 'College',
    component: Layout,
    redirect: '/college/collegeSetting',
    meta: { title: '学堂管理', icon: 'el-icon-setting' },
    children: [
      {
        path: 'collegeSetting',
        name: 'CollegeSetting',
        component: () => import('@/views/college/CollegeSetting'),
        meta: { title: '学堂设置' },
      },
      {
        path: 'accountsManage',
        name: 'AccountsManage',
        component: () => import('@/views/college/Accounts/accountsManage'),
        meta: { title: '账户管理' },
      },
      {
        path: 'setBankCard',
        name: 'SetBankCard',
        hidden: true,
        component: () => import('@/views/college/Accounts/setBankCard'),
        meta: { title: '设置银行卡' },
      },
      {
        path: 'admin',
        name: 'CollegeAdmin',
        component: () => import('@/views/college/CollegeAdmin/index'),
        meta: { title: '管理员管理' },
      },
      {
        path: 'changeAdmin',
        name: 'ChangeAdmin',
        hidden: true,
        component: () => import('@/views/college/CollegeAdmin/changeAdmin'),
        meta: { title: '更换管理员' },
      },
      {
        path: 'role',
        name: 'CollegeRole',
        component: () => import('@/views/college/CollegeRole/role'),
        meta: { title: '角色管理' },
      },
      {
        path: 'educational',
        name: 'EducationalManger',
        component: () => import('@/views/course/EducationalManger'),
        meta: { title: '教务人员管理' },
      },
      {
        path: 'educational/add',
        name: 'EducationalAdd',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "EducationalAdd" */ '@/views/course/EducationalManger/add'),
        meta: { title: '新增教务' },
      },
      {
        path: 'educational/batchAdd',
        name: 'EducationalBatchAdd',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "EducationalBatchAdd" */ '@/views/course/EducationalManger/batchAdd'
          ),
        meta: { title: '批量添加教务' },
      },
      {
        path: 'educational/edit/:studentId',
        name: 'EducationalEdit',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "EducationalEdit" */ '@/views/course/EducationalManger/edit'),
        meta: { title: '编辑教务' },
      },
      {
        path: 'CollegeRealName',
        name: 'CollegeRealName',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "CollegeRealName" */ '@/views/college/CollegeRealName/index'),
        meta: { title: '实名认证企业书院主体' },
      },
    ],
  },
]
