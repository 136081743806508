<template>
  <div class="college">
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :show-close="true"
      width="560px"
      title="所属书院列表"
      custom-class="change-org"
    >
      <div class="content">
        <div class="btnWrap">
          <div class="btn firstBtn" @click="created">
            <img src="../../../assets/dashboard/index-created.png" alt="" />
            <span>创建书院</span>
          </div>
          <div class="btn" @click="join">
            <img src="../../../assets/dashboard/index-join.png" alt="" />
            <span>加入其他书院</span>
          </div>
        </div>
        <div class="list">
          <div
            v-for="item in list"
            :key="item.organizationId"
            :class="['org-item', { select: selectOrgid == item.organizationId }]"
            @click="selectOrgid = item.organizationId"
          >
            <img :src="item.logo" alt="" />
            <div class="name">{{ item.organizationName }}</div>
            <div class="check">
              <img src="@/assets/common/tick-select-icon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { orgChange } from '@/api/login'
import to from 'await-to'
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      selectOrgid: 0,
    }
  },
  created() {},
  methods: {
    show() {
      this.dialogVisible = true
    },
    created() {
      window.location.href = process.env.VUE_APP_TEST
    },
    join() {
      window.location.href = process.env.VUE_APP_TEST
    },
    submit() {
      if (this.selectOrgid == 0) {
        this.$message.warning('请选择学院')
        return false
      }
      this.dialogVisible = false
      this.changeMyorg(this.selectOrgid)
    },
    async changeMyorg(val) {
      const [, err] = await to(orgChange({ organizationId: val }))
      if (err) return this.$message.warning(err.msg)
      location.reload()
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep .change-org {
  .el-dialog__header {
    padding: 0 20px;
    box-sizing: border-box;
    height: 55px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e2e2e2;
    font-size: 16px;
    color: #333333;
  }
  .el-dialog__body {
    padding: 0;
  }
  .content {
    padding: 10px 20px 23px;
    .btnWrap {
      padding-bottom: 20px;
      display: flex;
      justify-content: flex-end;
      .btn {
        text-align: center;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff7b33;
        border-radius: 4px;
        border: 1px solid #ff7b33;
        cursor: pointer;
        width: 123px;
        height: 34px;
      }
      .btn img {
        width: 16px;
        height: 16px;
      }
      .btn span {
        margin-left: 3px;
      }
      .firstBtn {
        background-color: #ff7b33;
        color: #ffffff;
        margin-right: 20px;
        width: 96px;
      }
    }
    .list {
      width: 102%;
      height: 482px;
      padding-top: 3px;
      overflow-x: hidden;
      overflow-y: auto;
      .org-item {
        position: relative;
        cursor: pointer;
        float: left;
        box-sizing: border-box;
        text-align: center;
        width: 154px;
        height: 147px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #c2c2c2;
        padding-top: 20px;
        margin-right: 29px;
        margin-bottom: 20px;
        &:nth-of-type(3n) {
          margin: 0;
        }
        img {
          display: block;
          width: 70px;
          height: 70px;
          margin: 0 auto;
          margin-bottom: 10px;
        }
        .name {
          font-size: 14px;
          color: #333333;
          line-height: 14px;
          width: 96%;
          margin: 0 auto;
          @extend %one-line-text;
        }
        .check {
          position: absolute;
          right: 10px;
          top: 10px;
          width: 18px;
          height: 18px;
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 2px;
          padding-top: 5px;
          box-sizing: border-box;
          img {
            width: 10px;
            height: 8px;
            opacity: 0;
          }
        }
        &.select {
          background: #ffede4;
          border: 1px solid #ff7b33;
          .check {
            border: 0;
            background: #ffede4;
            img {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .el-dialog__footer {
    border: 1px solid #e2e2e2;
    padding: 10px 10px;
    height: 52px;
    .dialog-footer {
      display: flex;
      align-items: center;
      padding-right: 17px;
      justify-content: flex-end;
      .el-button {
        width: 80px;
        height: 32px;
      }
    }
  }
}
</style>
