/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-01-19 10:28:39
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import getTitle from '@/utils/get-title'
import store from '@/store'
import * as type from '@/store/action-types'
import { getCookie } from '@/utils/cookies'
import { getLocal } from '@/utils/local'
import 'nprogress/nprogress.css'
import Layout from '@/layout'
Vue.use(VueRouter)

let constantRouterMap = [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/dashbord',
    children: [
      {
        path: '/dashbord',
        name: 'Dashbord',
        component: () => import(/*webpackChunkName:'Dashboard'*/ '@/views/dashboard'),
        meta: { title: '首页', icon: 'el-icon-s-data', affix: true },
      },
      {
        path: '/dashbord/quick',
        name: 'Quick',
        hidden: true,
        component: () => import('@/views/dashboard/quick'),
        meta: { title: '添加快捷入口' },
      },
      {
        path: '/dashbord/allNews',
        name: 'AllNews',
        hidden: true,
        component: () => import('@/views/dashboard/allNews'),
        meta: { title: '全部动态' },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    hidden: true,
    component: () => import('@/views/login/login'),
    meta: { title: '登录' },
  },
  {
    path: '/home',
    name: 'Home',
    hidden: true,
    component: () => import('@/views/home/index'),
    meta: { title: '首页' },
  },
  {
    path: '/home/intro',
    name: 'HomeIntro',
    hidden: true,
    component: () => import('@/views/home/intro'),
    meta: { title: '功能介绍' },
  },
  {
    path: '/create',
    name: 'create',
    hidden: true,
    component: () => import('@/views/create/createMain'),
    meta: { title: '创建学堂基本信息' },
  },
  {
    path: '/createMid',
    name: 'createMid',
    hidden: true,
    component: () => import('@/views/create/createMid'),
    meta: { title: '创建学堂主体信息' },
  },
  {
    path: '/createSuc',
    name: 'createSuc',
    hidden: true,
    component: () => import('@/views/create/createSuc'),
    meta: { title: '创建学堂成功' },
  },
  {
    path: '/userauth',
    name: 'userauth',
    hidden: true,
    component: () => import('@/views/login/userauth'),
    meta: { title: '实名认证' },
  },
  {
    path: '/createCollege',
    name: 'createCollege',
    hidden: true,
    component: () => import('@/views/create/createCollege'),
    meta: { title: '创建学堂' },
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    hidden: true,
    component: () => import('@/views/login/userCenter'),
    meta: { title: '个人中心' },
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import(/*webpackChunkName:'Redirect'*/ '@/views/redirect'),
      },
    ],
  },
  {
    path: '/exception',
    name: 'ErrorPage',
    component: Layout,
    type: 'system',
    children: [
      {
        path: '404',
        name: '404',
        component: () => import(/* webpackChunkName: '404' */ '@/views/error/404.vue'),
        hidden: true,
        meta: { title: '404' },
      },
    ],
  },
  // {
  //   path: '*',
  //   name: '*404',
  //   redirect: '/exception/404',
  //   hidden: true,
  // },
]
let asyncRouterMap = []
const files = require.context('./modules/', false, /\.router.js$/)
files.keys().forEach(key => {
  const file = files(key).default || files(key)
  asyncRouterMap.push(...file)
})
const router = new VueRouter({
  mode: 'history',
  routes: [...constantRouterMap, ...asyncRouterMap],
})

NProgress.configure({
  showSpinner: false,
})

function getMenu(path, menus) {
  for (let i = 0; i < menus.length; i++) {
    let menu = menus[i]
    if (path === menu.path) {
      return true
    } else if (menu.children && menu.children.length) {
      for (let i = 0; i < menu.children.length; i++) {
        const child = menu.children[i]
        if (path === menu.path + '/' + child.path || path === child.path) {
          return true
        }
      }
    }
  }
  return false
}

router.beforeEach((to, from, next) => {
  document.title = getTitle(to.meta.title)
  NProgress.start()
  if (
    to.path === '/home' ||
    to.path === '/home/' ||
    to.path === '/home/intro' ||
    to.path === '/home/intro/'
  )
    return next()
  if (!getCookie('myToken')) {
    next({
      path: '/home',
    })
  } else {
    // 重拿用户信息
    if (JSON.stringify(store.state.user.userInfo) == '{}') {
      const userInfo = getCookie('userInfo')
      JSON.stringify(userInfo) !== '{}' && store.commit(`user/${type.SET_USER_INFO}`, userInfo)
    }
    console.log(to.path)
    console.log(
      getMenu('/' + to.path.split('/')[1], getLocal('menuList', true)),
      getMenu(to.path, constantRouterMap),
    )
    if (
      getMenu('/' + to.path.split('/')[1], getLocal('menuList', true)) ||
      getMenu(to.path, constantRouterMap)
    ) {
      next()
    } else {
      next('/')
    }
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.afterEach(() => {
  NProgress.done()
})

export default router
