<!--
 * @Description: 侧边菜单导航
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-06-04 19:57:34
-->
<template>
  <div class="menu">
    <el-scrollbar>
      <el-menu
        class="el-menu-vertical-demo"
        :default-active="activeMenu"
        background-color="#4D4E5D"
        text-color="#c9c9c9"
        active-text-color="#ffffff"
        mode="vertical"
        :collapse-transition="false"
        :collapse="opened"
      >
        <page-link to="/dashbord">
          <el-menu-item index="/dashbord">
            <i class="el-icon-s-home"></i>
            <span slot="title">首页</span>
          </el-menu-item>
        </page-link>
        <div v-for="item in menuList" :key="item.path">
          <side-menu-item :item="item" :base-path="item.path"></side-menu-item>
        </div>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { getUserMenu } from '@/api/college'
import to from 'await-to'
import SideMenuItem from './SideMenuItem'
import { mapGetters } from 'vuex'
import PageLink from './Link'
export default {
  components: { SideMenuItem, PageLink },
  data() {
    return {
      routes: this.$router.options.routes,
      clientCode: '0a983d86-2527-5512-01a9-783cd0e1',
      menuList: [],
    }
  },
  computed: {
    ...mapGetters(['opened', 'menuType']),
    activeMenu() {
      return this.$route.path
    },
  },
  created() {
    this.getMenuData()
  },
  methods: {
    async getMenuData() {
      const [res, err] = await to(getUserMenu({ clientCode: this.clientCode }))
      if (err) return
      this.menuList = res.data
    },
  },
}
</script>
