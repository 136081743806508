<!--
 * @Description: 面包屑
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-27 18:45:40
-->
<template>
  <div v-if="$route.path != '/dashbord'" class="bread-crumb">
    <el-breadcrumb separator="/">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item v-for="item in breadList" :key="item.path" :to="item.path">
          {{ item.meta.title }}
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadList: [],
    }
  },
  watch: {
    $route: {
      handler(route) {
        let allList = route.matched.filter(item => {
          if (item.meta && item.meta.title) {
            // if (item.redirect) {
            //   item.path = ''
            // }
            return true
          }
        })
        if (allList.length && allList[0].path !== '/' && allList[0].path !== '/dashbord') {
          allList.unshift({ path: '/', meta: { title: '首页' } })
        }
        this.breadList = allList
      },
      immediate: true,
    },
  },
}
</script>
