import config from './config/login'
import axios from '@/utils/request'
export const login = params => axios.post(config.login, params) // 登录
export const loginverifycode = params => axios.post(config.loginverifycode, params) // 验证码登录
export const verifycode = params => axios.post(config.verifycode, params) // 验证码
export const register = params => axios.post(config.register, params) // 注册
export const myorg = params => axios.post(config.myorg, params) // 我的机构
export const orgChange = params => axios.post(config.orgChange, params) // 切换机构
export const userAuthQuery = params => axios.post(config.userAuthQuery, params) // 权限查询
export const userRealAuth = params => axios.post(config.userRealAuth, params) // 实名认证
export const getUser = params => axios.get(config.getUser, params) // 获取用户数据
export const getUserData = params => axios.post(config.getUserData, params) // 提交用户数据
export const ucVerifyCode = params => axios.post(config.ucVerifyCode, params) // 更换手机验证码
export const ucChangePhone = params => axios.post(config.ucChangePhone, params) // 更换手机接口
export const passVerifyCode = params => axios.post(config.passVerifyCode, params) // 更换密码验证码
export const updatePassword = params => axios.post(config.updatePassword, params) // 更换密码接口
export const suggest = params => axios.post(config.suggest, params) // 投诉建议
