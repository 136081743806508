/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2022-08-26 10:23:27
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-09-20 09:57:26
 */
import config from './config/live'
import axios from '@/utils/request'
export const liveList = params => axios.post(config.liveList, params) // 直播列表
export const updateStatus = params => axios.post(config.updateStatus, params) // 修改直播状态
export const createdLive = params => axios.post(config.createdLive, params) // 创建直播
export const editSearch = params => axios.post(config.editSearch, params) // 编辑查询直播
export const editLive = params => axios.post(config.editLive, params) // 编辑直播
export const goLive = params => axios.post(config.goLive, params) // 去直播
export const teacherSelect = params => axios.post(config.teacherSelect, params) // 讲师下拉
export const liveRecord = params => axios.post(config.liveRecord, params) // 直播记录
export const livePlayBack = params => axios.post(config.livePlayBack, params) //直播回放
export const delLive = params => axios.post(config.delLive, params) //直播删除
export const liveCourse = params => axios.post(config.liveCourse, params) //我的直播课列表
export const liveAudience = params => axios.post(config.liveAudience, params) //直播观众
export const imAddRoom = params => axios.post(config.imAddRoom, params) //im加入群聊
export const getUSerName = params => axios.post(config.getUSerName, params) //im里面获取姓名
export const vidSearch = params => axios.post(config.vidSearch, params) //通过vid查询视频信息
export const liveStudyRecord = params => axios.post(config.liveStudyRecord, params) //提交学习记录
export const livePlaybackUpload = params => axios.post(config.livePlaybackUpload, params) //回放修改
export const liveStatusChange = params => axios.post(config.liveStatusChange, params) //直播状态更新
