/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-05-17 08:36:40
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-06-03 18:57:33
 */
const title = '明师道'
const getTitle = function(til) {
  console.log(til)
  // let allTitle = til + ' - ' + title
  let allTitle = title
  return allTitle
}
export default getTitle
