<!--
 * @Description: 用户右上角信息下拉菜单
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-11-24 14:55:20
-->
<template>
  <div class="user-dropdown">
    <el-dropdown trigger="click">
      <div class="user-info">
        <img :src="userInfo.headPic" :onerror="defaultAvatar" />
        <span class="name">
          {{ userName }}
          <i class="el-icon-caret-bottom"></i>
        </span>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <router-link to="/"><i class="el-icon-s-home"></i>首页</router-link>
        </el-dropdown-item>
        <el-dropdown-item divided>
          <a @click="logout"><i class="el-icon-switch-button"></i>退出登录</a>
        </el-dropdown-item>
        <el-dropdown-item>
          <a @click="enterUser"><i class="el-icon-user"></i>个人中心</a>
        </el-dropdown-item>
        <el-dropdown-item>
          <!-- <el-popover placement="left" trigger="hover">
            <div v-for="(item, index) in organization" :key="index" class="showorg">
              <a @click="changeMyorg(item.organizationId)">{{ item.organizationName }}</a>
            </div>
            <div slot="reference"><i class="el-icon-menu"></i>切换学堂</div>
          </el-popover> -->
          <a @click="changeCollege"><i class="el-icon-menu"></i>切换书院</a>
        </el-dropdown-item>
        <!-- <el-dropdown-item>
          <a @click="enterCreate"><i class="el-icon-plus"></i>创建学堂</a>
        </el-dropdown-item> -->
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { getCookie } from '@/utils/cookies'
import * as type from '@/store/action-types'
import { mapGetters, createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('user')
import { myorg, orgChange, userAuthQuery } from '@/api/login'
import { getSearchCollegeNews } from '@/api/college'
import to from 'await-to'
export default {
  data() {
    return {
      defaultAvatar: `this.src="${require('./avatar.png')}"`,
      organization: [],
      pager: {
        total: 0,
        size: 100,
        current: 1,
      },
      userName: getCookie('userName'),
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.myorgData()
  },
  methods: {
    ...mapActions([type.LOGOUT]),
    logout() {
      this[type.LOGOUT]()
    },
    async myorgData() {
      const [res, err] = await to(myorg({ ...this.pager }))
      if (err) return this.$message.warning(err.msg)
      this.organization = res.data.list
      if (res.data.total == 0) {
        this.$router.push('/home')
      } else {
        await to(orgChange({ organizationId: this.organization[0].organizationId }))
        this.getCollegeInfo()
        const [res, err] = await to(userAuthQuery({}))
        if (err) return this.$message.warning(err.msg)
        if (!res.data.realNameAuth) {
          this.$router.push(`/userauth`)
        }
      }
    },
    async getCollegeInfo() {
      const [res, err] = await to(getSearchCollegeNews({}))
      if (err) return
      this.$store.commit(`user/${type.SET_COLLEGE_INFO}`, res.data)
    },
    async changeMyorg(val) {
      const [, err] = await to(orgChange({ organizationId: val }))
      if (err) return this.$message.warning(err.msg)
      location.reload()
    },
    enterUser() {
      this.$router.push('/userCenter')
    },
    enterCreate() {
      this.$router.push('/createCollege')
    },
    changeCollege() {
      this.$emit('change', this.organization)
    },
  },
}
</script>
<style scoped>
.showorg a {
  display: block;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  border: 1px solid #e2e2e2;
}
.showorg a:hover {
  background: #ff955c;
  color: #fff;
}
</style>
