/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-05-17 08:36:40
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-01-10 11:12:45
 */
import Layout from '@/layout'

export default [
  {
    path: '/scenter',
    name: 'scenter',
    component: Layout,
    redirect: '/scenter/certificateAgentList',
    meta: { title: '营销中心', icon: 'el-icon-s-platform' },
    children: [
      {
        path: 'sallManger',
        name: 'sallManger',
        component: () => import('@/views/center/sallManger'),
        meta: { title: '销售管理' },
      },
      {
        path: 'productManage',
        name: 'ProductManage',
        component: () =>
          import(/* webpackChunkName: "ProductManage" */ '@/views/center/ProductManage'),
        meta: { title: '商品管理' },
      },
      {
        path: 'productManage/add',
        name: 'ProductManageAdd',
        component: () =>
          import(/* webpackChunkName: "ProductManageAdd" */ '@/views/center/ProductManage/add'),
        meta: { title: '编辑商品' },
      },
      {
        path: 'productManage/:productId',
        name: 'ProductManageEdit',
        component: () =>
          import(/* webpackChunkName: "ProductManageEdit" */ '@/views/center/ProductManage/add'),
        meta: { title: '编辑商品' },
      },
      {
        path: 'productDetail',
        name: 'productDetail',
        component: () => import('@/views/center/ProductManage/detail'),
        meta: { title: '销售详情' },
      },
      {
        path: 'mySalesRecord',
        name: 'MySalesRecord',
        component: () =>
          import(/* webpackChunkName: "MySalesRecord" */ '@/views/center/MySalesRecord'),
        meta: { title: '我的销售记录' },
      },
      {
        path: 'mySalesRecord/add',
        name: 'MySalesRecordAdd',
        component: () =>
          import(/* webpackChunkName: "MySalesRecordAdd" */ '@/views/center/MySalesRecord/add'),
        meta: { title: '添加报名信息' },
      },
      {
        path: 'offlineRecordReview',
        name: 'OfflineRecordReview',
        component: () =>
          import(
            /* webpackChunkName: "OfflineRecordReview" */ '@/views/center/OfflineRecordReview'
          ),
        meta: { title: '线下报名记录审核' },
      },
      {
        path: 'sallManger/add',
        name: 'sallMangerAdd',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "CourseResourcesAdd" */ '@/views/center/sallManger/add'),
        meta: { title: '创建商品' },
      },
      {
        path: 'sallManger/edit',
        name: 'sallMangerEdit',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "CourseResourcesAdd" */ '@/views/center/sallManger/edit'),
        meta: { title: '修改商品' },
      },
      {
        path: 'videoManger',
        name: 'videoManger',
        component: () => import('@/views/center/videoManger'),
        meta: { title: '短视频营销管理' },
      },
      {
        path: 'videoManger/add',
        name: 'videoMangerAdd',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "CourseResourcesAdd" */ '@/views/center/videoManger/add'),
        meta: { title: '发布短视频' },
      },
      {
        path: 'certificateAgentList',
        name: 'CertificateAgentList',
        component: () => import('@/views/certificateManage/agentManage/index'),
        meta: { title: '代理商管理' },
      },
      {
        path: 'agentDetail',
        name: 'AgentDetail',
        component: () => import('@/views/certificateManage/agentManage/agentDetail'),
        meta: { title: '代理商详情' },
      },
    ],
  },
]
