<!--
 * @Description: 公共布局
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-12-29 14:37:39
-->
<template>
  <div class="wrapper" :class="{ closeBar: opened }">
    <MHeader @change="change" />
    <div class="wrapper_main">
      <SideMenu />
      <div class="main-container">
        <BreadCrumb />
        <PageMain />
        <College ref="College" :list="list" />
      </div>
    </div>
  </div>
</template>

<script>
import College from '../views/dashboard/components/CollegeList.vue'
import SideMenu from './components/SideMenu'
import MHeader from './components/Header'
import PageMain from './components/PageMain'
import BreadCrumb from './components/BreadCrumb'
import { mapGetters } from 'vuex'
export default {
  name: 'Layout',
  components: {
    SideMenu,
    MHeader,
    PageMain,
    BreadCrumb,
    College,
  },
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapGetters(['opened']),
  },
  methods: {
    change(arr) {
      this.list = arr
      this.$refs.College.show()
    },
  },
}
</script>
