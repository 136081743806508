<!--
 * @Description: 公共头部
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-19 16:01:50
-->
<template>
  <div class="main-header">
    <div class="main-header-logo">
      <router-link to="/" class="logoLink">
        <img :src="collegeInfo.logo" alt="" />
        {{ collegeInfo.organizationName }}管理系统
      </router-link>
    </div>
    <div class="main-header-main">
      <full-screen></full-screen>
      <user-dropdown @change="change"></user-dropdown>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('user')
import UserDropdown from '../UserDropdown'
import FullScreen from '../FullScreen'
// import { myorg } from '@/api/create'
export default {
  components: {
    UserDropdown,
    FullScreen,
  },
  computed: {
    ...mapState(['collegeInfo']),
  },
  methods: {
    change(arr) {
      this.$emit('change', arr)
    },
  },
}
</script>
