export default {
  liveList: '/course/supply/findLiveRoom/byPage',
  updateStatus: '/course/supply/updateLiveRoom/publishStatus',
  createdLive: '/course/supply/createLive',
  editSearch: '/course/supply/live/info',
  editLive: '/course/supply/updateLiveRoom/updateInformation',
  goLive: '/course/supply/live/authInfo',
  teacherSelect: '/course/supply/query/liveteacher/selectlist',
  liveRecord: '/course/supply/live/audience/page',
  livePlayBack: '/course/liveRoom/query/liveroom/playback',
  delLive: '/course/supply/delete/liveroom',
  liveCourse: '/course/supply/mylive/page',
  liveAudience: '/course/supply/myLive/online/user',
  imAddRoom: '/course/userLive/addImRoom',
  getUSerName: '/course/identify/im/user/query/by/imaccount',
  vidSearch: '/course/video/play/info',
  liveStudyRecord: '/course/identify/learncentre/study/record/update',
  livePlaybackUpload: '/course/supply/live/playback/upload',
  liveStatusChange: '/course/supply/live/status/change',
}
