<template>
  <div class="pageMain">
    <transition name="fade-page" mode="out-in">
      <router-view :key="key"></router-view>
    </transition>
  </div>
</template>
<script>
export default {
  computed: {
    key() {
      return this.$route.path
    },
  },
}
</script>
