<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-05-17 08:36:40
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-05-09 15:38:03
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import * as type from '@/store/action-types'
import { getUserMenu } from '@/api/college'
import { setCookie, getCookie } from '@/utils/cookies'
import { setLocal } from '@/utils/local'
import { mapGetters } from 'vuex'
import to from 'await-to'
import { getUser } from '@/api/login'
export default {
  name: 'App',
  data() {
    return {
      visitArr: [],
      menuList: [],
      clientCode: '0a983d86-2527-5512-01a9-783cd0e1',
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  watch: {
    $route(to) {
      if (getCookie('myToken')) {
        this.filterMenu(to)
      }
    },
  },
  mounted() {
    this.$router.onReady(() => {
      if (this.$route.query.token) {
        setCookie('myToken', this.$route.query.token)
      }
      if (getCookie('myToken')) {
        this.getUser()
        this.getCollegeInfo()
        this.getMenuData()
      }
    })
  },
  methods: {
    async getUser() {
      const [res, err] = await to(getUser())
      if (err) return this.$message.warning(err.msg)
      setCookie('userInfo', res.data)
      setCookie('headPic', res.data.headPic) //头像
      setCookie('nickName', res.data.nickName) //昵称
      setCookie('userName', res.data.userName) //用户姓名
      setCookie('email', res.data.email) //邮箱
      setCookie('sex', res.data.sex) //性别
      setCookie('phone', res.data.phone) //用户手机号
      setCookie('imUserName', res.data.imUserName) //im
      setCookie('imSecret', res.data.imSecret) //im
      this.$store.commit(`user/${type.SET_USER_INFO}`, res.data)
    },
    filterMenu(to) {
      if (JSON.parse(sessionStorage.getItem('teacherMenu'))) {
        this.menuList = JSON.parse(sessionStorage.getItem('teacherMenu'))
        this.menuList.map(v => {
          if ((v.children && v.children.length !== 0) || to.path == '/dashbord') {
            v.children.map(k => {
              if (to.path == v.path + '/' + k.path || to.path == '/dashbord') {
                if (
                  JSON.parse(localStorage.getItem(this.userInfo.phone + 'visit' + this.clientCode))
                ) {
                  this.visitArr = JSON.parse(
                    localStorage.getItem(this.userInfo.phone + 'visit' + this.clientCode),
                  )
                }
                let obj = {
                  name: to.meta.title,
                  path: to.path,
                }
                if (
                  JSON.stringify(this.visitArr).indexOf(JSON.stringify(obj)) == -1 &&
                  to.path !== '/exception/404'
                )
                  this.visitArr.unshift(obj)
                if (this.visitArr.length > 8) {
                  this.visitArr.pop()
                }
                localStorage.setItem(
                  this.userInfo.phone + 'visit' + this.clientCode,
                  JSON.stringify(this.visitArr),
                )
              }
            })
          }
        })
      } else {
        let obj = {
          name: to.meta.title,
          path: to.path,
        }
        if (
          JSON.stringify(this.visitArr).indexOf(JSON.stringify(obj)) == -1 &&
          to.path !== '/exception/404'
        )
          this.visitArr.unshift(obj)
        if (this.visitArr.length > 8) {
          this.visitArr.pop()
        }
        localStorage.setItem(
          this.userInfo.phone + 'visit' + this.clientCode,
          JSON.stringify(this.visitArr),
        )
      }
    },
    async getCollegeInfo() {
      // const [res, err] = await to(getSearchCollegeNews({}))
      // if (err) return
      // this.$store.commit(`user/${type.SET_COLLEGE_INFO}`, res.data)
      if (!JSON.parse(localStorage.getItem(this.userInfo.phone + 'visit' + this.clientCode))) {
        localStorage.setItem(
          this.userInfo.phone + 'visit' + this.clientCode,
          JSON.stringify(this.visitArr),
        )
      } else {
        this.visitArr = JSON.parse(
          localStorage.getItem(this.userInfo.phone + 'visit' + this.clientCode),
        )
      }
    },
    async getMenuData() {
      const [res, err] = await to(getUserMenu({ clientCode: this.clientCode }))
      if (err) return this.$message.warning(err.msg)
      this.menuList = res.data
      console.log(res.data, 123)
      if (res.data && res.data !== null) {
        sessionStorage.setItem('teacherMenu', JSON.stringify(res.data))
        console.log(res.data, 456)
        setLocal('menuList', res.data)
      }
    },
  },
}
</script>
