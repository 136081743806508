import Layout from '@/layout'

export default [
  {
    path: '/course',
    name: 'Course',
    component: Layout,
    redirect: '/course/resources',
    meta: { title: '教学内容中心', icon: 'el-icon-files' },
    children: [
      {
        path: 'resources',
        name: 'CourseResources',
        component: () =>
          import(/* webpackChunkName: "CourseResources" */ '@/views/course/CourseResources'),
        meta: { title: '课程资源管理' },
      },
      {
        path: 'resources/add',
        name: 'CourseResourcesAdd',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "CourseResourcesAdd" */ '@/views/course/CourseResources/add'),
        meta: { title: '添加课程资源' },
      },
      {
        path: 'resources/edit/:id',
        name: 'CourseResourcesEdit',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseResourcesEdit" */ '@/views/course/CourseResources/add'
          ),
        meta: { title: '编辑课程资源' },
      },
      {
        path: 'resources/progress/:courseId',
        name: 'CourseProgress',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseProgress" */ '@/views/course/CourseResources/progress'
          ),
        meta: { title: '上传进度' },
      },
      {
        path: 'library',
        name: 'CourseLibrary',
        component: () =>
          import(/* webpackChunkName: "CourseLibrary" */ '@/views/course/CourseLibrary'),
        meta: { title: '学堂课程库' },
      },
      {
        path: 'library/add',
        name: 'CourseLibraryAdd',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "CourseLibraryAdd" */ '@/views/course/CourseLibrary/add'),
        meta: { title: '创建课程' },
      },
      {
        path: 'library/edit/:courseId',
        name: 'CourseLibraryEdit',
        hidden: true,
        component: () =>
          import(/* webpackChunkName: "CourseLibraryEdit" */ '@/views/course/CourseLibrary/add'),
        meta: { title: '编辑课程' },
      },
      {
        path: 'library/section/:courseId',
        name: 'CourseLibrarySection',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseLibrarySection" */ '@/views/course/CourseLibrary/section'
          ),
        meta: { title: '章节管理' },
      },
      {
        path: 'class',
        name: 'ClassManger',
        component: () => import(/* webpackChunkName: "CourseShop" */ '@/views/course/ClassManger'),
        meta: { title: '班级管理' },
      },
      {
        path: 'classManger/classTotal',
        name: 'ClassTotal',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseLibrarySection" */ '@/views/course/ClassManger/classTotal'
          ),
        meta: { title: '班级数据统计' },
      },
      {
        path: 'classManger/createclass',
        name: 'createclass',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseLibrarySection" */ '@/views/course/ClassManger/createclass'
          ),
        meta: { title: '创建班级' },
      },
      {
        path: 'classManger/edit',
        name: 'CourseEdit',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseLibraryEdit" */ '@/views/course/ClassManger/createclass'
          ),
        meta: { title: '修改课程' },
      },
      {
        path: 'classManger/courseAdd',
        name: 'CourseAdd',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseLibraryEdit" */ '@/views/course/ClassManger/courseAdd'
          ),
        meta: { title: '已有课程' },
      },
      {
        path: 'classManger/coursePlans',
        name: 'CoursePlans',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseLibraryEdit" */ '@/views/course/ClassManger/coursePlans'
          ),
        meta: { title: '制定培训计划' },
      },
      {
        path: 'classManger/classInfo',
        name: 'classInfo',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseLibraryEdit" */ '@/views/course/ClassManger/classInfo'
          ),
        meta: { title: '班级详情' },
      },
      {
        path: 'classManger/classSure',
        name: 'classSure',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseLibraryEdit" */ '@/views/course/ClassManger/classSure'
          ),
        meta: { title: '确认班级信息' },
      },
      {
        path: 'classManger/studyInfo',
        name: 'studyInfo',
        hidden: true,
        component: () =>
          import(
            /* webpackChunkName: "CourseLibraryEdit" */ '@/views/course/ClassManger/studyInfo'
          ),
        meta: { title: '学习详情' },
      },
      {
        path: 'customer',
        name: 'CustomerManger',
        component: () => import('@/views/course/CustomerManger'),
        meta: { title: '客户管理' },
      },
      {
        path: 'examList',
        name: 'examList',
        component: () =>
          import(/* webpackChunkName: "CourseResources" */ '@/views/course/examList/index'),
        meta: { title: '考证题库' },
      },
      {
        path: 'examDetail',
        name: 'examDetail',
        component: () =>
          import(/* webpackChunkName: "CourseResources" */ '@/views/course/examList/examDetail'),
        meta: { title: '试题列表' },
      },
      {
        path: 'customer/:userId',
        name: 'CustomerMangerDetail',
        hidden: true,
        component: () => import('@/views/course/CustomerManger/detail'),
        meta: { title: '客户信息' },
      },
    ],
  },
]
