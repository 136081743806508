/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-05-17 08:36:40
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-06-04 10:55:39
 */
// 用户相关
export const LOGIN = 'LOGIN' // 登录
export const LOGOUT = 'LOGOUT' // 登出
export const SET_USER_INFO = 'SET_USER_INFO' // 设置用户信息
export const SET_TOKEN = 'SET_TOKEN' // 设置token
export const SET_SIDE_OPENED = 'SET_SIDE_OPENED' // 设置侧边栏收起展开状态
export const SET_COLLEGE_INFO = 'SET_COLLEGE_INFO' // 设置学院信息

//step
export const SET_STEP = 'SET_STEP' //设置上下一步

//im
export const SET_IM_MSG = 'SET_IM_MSG' // 设置IM信息
export const CHANGE_IM_MSG = 'CHANGE_IM_MSG' // 改变IM信息
export const CLEAR_IM_MSG = 'CLEAR_IM_MSG' // 清除IM信息
