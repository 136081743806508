/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-20 10:44:00
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-06-04 14:56:40
 */
import * as type from '../action-types'
import { login } from '@/api/login'
import { Message } from 'element-ui'
import { setCookie } from '@/utils/cookies'
import { removeCookie } from '@/utils/cookies'
import { getUSerName } from '@/api/live'
import to from 'await-to'
export default {
  state: {
    token: '',
    userInfo: {},
    collegeInfo: {},
    step: 0,
    classLiveMsg: [],
  },
  mutations: {
    [type.SET_USER_INFO](state, payload) {
      state.userInfo = payload
      setCookie('userInfo', payload)
    },
    [type.SET_TOKEN](state, payload) {
      state.token = payload.token
      setCookie('myToken', payload.token)
    },
    [type.SET_COLLEGE_INFO](state, payload) {
      state.collegeInfo = payload
    },
    [type.SET_STEP](state, payload) {
      if (payload == 1) {
        state.step = payload
      } else if (payload == 2) {
        state.step = 0
      }
    },
    [type.CHANGE_IM_MSG](state, payload) {
      if (payload.message.type == 2) {
        let obj = {
          sourceMsg: payload.message.message.sourceMsg,
          id: payload.message.message.id,
          from: payload.result,
          type: 2,
        }
        state.classLiveMsg.push(obj)
      }

      if (payload.type == 1) {
        let obj = {
          sourceMsg: payload.message,
          id: payload.id,
          from: '',
          type: 1,
        }
        state.classLiveMsg.push(obj)
      }
    },
    [type.CLEAR_IM_MSG](state, payload) {
      state.classLiveMsg = payload
    },
  },
  actions: {
    async [type.SET_IM_MSG]({ commit }, formData) {
      console.log(formData)
      if (formData.type == 2) {
        const [res, err] = await to(getUSerName({ userImAccount: formData.message.from }))
        if (err) return this.$message.warning(err.msg)
        commit(type.CHANGE_IM_MSG, { message: formData, result: res.data.nickName })
      } else {
        commit(type.CHANGE_IM_MSG, formData)
      }
    },
    async [type.LOGIN]({ commit }, formData) {
      const [res, err] = await to(login(formData))
      if (err) return Message.warning(err.msg)
      commit(type.SET_USER_INFO, res.data)
      return res
    },
    async [type.LOGOUT]() {
      removeCookie('myToken')
      removeCookie('userInfo')
      removeCookie('sex')
      removeCookie('userName')
      removeCookie('SupplyrealAuth')
      removeCookie('phone')
      removeCookie('nickName')
      removeCookie('email')
      removeCookie('headPic')
      // var keys = document.cookie.match(/[^ =;]+(?==)/g)
      // if (keys) {
      //   keys.map(v => {
      //     removeCookie(v)
      //   })
      // }
      sessionStorage.clear()
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    },
  },
}
