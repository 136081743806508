/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-05-17 08:36:40
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-18 17:43:31
 */
import Layout from '@/layout'

export default [
  {
    path: '/teacher',
    name: 'Teacher',
    component: Layout,
    redirect: '/teacher/list',
    meta: { title: '讲师管理', icon: 'el-icon-user' },
    children: [
      {
        path: 'list',
        name: 'TeacherMangerList',
        component: () => import('@/views/teacher/TeacherManger'),
        meta: { title: '讲师列表' },
      },
      {
        path: 'add',
        name: 'TeacherMangerAdd',
        hidden: true,
        component: () => import('@/views/teacher/TeacherManger/add'),
        meta: { title: '添加讲师' },
      },
      {
        path: 'edit/:teacherId',
        name: 'TeacherMangerEdit',
        hidden: true,
        component: () => import('@/views/teacher/TeacherManger/add'),
        meta: { title: '编辑讲师' },
      },
      {
        path: 'open',
        name: 'TeacherMangerOpen',
        hidden: true,
        component: () => import('@/views/teacher/TeacherManger/open'),
        meta: { title: '设置对外开放类型' },
      },
      {
        path: 'offlineCoursesList',
        name: 'OfflineCoursesList',
        hidden: true,
        component: () => import('@/views/teacher/OfflineCourses/list'),
        meta: { title: '线下课程' },
      },
      {
        path: 'createdOfflineCourses',
        name: 'CreatedOfflineCourses',
        hidden: true,
        component: () => import('@/views/teacher/OfflineCourses/add'),
        meta: { title: '添加课程' },
      },
      {
        path: 'eidtOfflineCourses',
        name: 'EidtOfflineCourses',
        hidden: true,
        component: () => import('@/views/teacher/OfflineCourses/add'),
        meta: { title: '编辑课程' },
      },
      {
        path: 'lectureVideoList',
        name: 'LectureVideoList',
        hidden: true,
        component: () => import('@/views/teacher/LectureVideo/list'),
        meta: { title: '授课视频列表' },
      },
      {
        path: 'myTeacherCourse',
        name: 'MyTeacherCourse',
        hidden: true,
        component: () => import('@/views/teacher/MyCourse/index'),
        meta: { title: '我的课程' },
      },
      {
        path: 'myOrderList',
        name: 'myOrderList',
        hidden: true,
        component: () => import('@/views/teacher/myOrderList/index'),
        meta: { title: '我的订单' },
      },
      {
        path: 'myOrderDetail',
        name: 'myOrderDetail',
        hidden: true,
        component: () => import('@/views/teacher/myOrderList/detail'),
        meta: { title: '订单详情' },
      },
      {
        path: 'statistics',
        name: 'statistics',
        hidden: true,
        component: () => import('@/views/teacher/myOrderList/statistics'),
        meta: { title: '销售统计' },
      },
      {
        path: 'details',
        name: 'details',
        hidden: true,
        component: () => import('@/views/teacher/myOrderList/details'),
        meta: { title: '收支明细' },
      },
      {
        path: 'myCourseRecordList/:productId',
        name: 'myCourseRecordList',
        hidden: true,
        component: () => import('@/views/teacher/MyCourse/recordList'),
        meta: { title: '销售记录' },
      },
      {
        path: 'myCourseDetail/:productId',
        name: 'myCourseDetail',
        hidden: true,
        component: () => import('@/views/teacher/MyCourse/detail'),
        meta: { title: '查看详情' },
      },
      {
        path: ':teacherId',
        name: 'TeacherMangerDetail',
        hidden: true,
        component: () => import('@/views/teacher/TeacherManger/detail'),
        meta: { title: '讲师详情' },
      },
    ],
  },
]
