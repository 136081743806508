import config from './config/college'
import axios from '@/utils/request'
export const getSearchCollegeNews = params => axios.post(config.searchCollege, params) // 查询学院信息
export const getEditCollegeNews = params => axios.post(config.editCollege, params) // 編輯学院信息
export const getAdminList = params => axios.post(config.adminList, params) //管理员列表
export const getAdminAddSubmit = params => axios.post(config.adminAdd, params) //管理员添加(表單)
export const getAdminAddSubmitTable = params => axios.post(config.adminAddTable, params) //管理员添加(表格)
export const getAllRole = params => axios.post(config.allRole, params) //全部角色
export const getAddRole = params => axios.post(config.addRole, params) //添加角色
export const getEditRole = params => axios.post(config.editRole, params) //编辑角色
export const getBatchDelRole = params => axios.post(config.batchDelRole, params) //删除角色
export const getRoleJurisdiction = params => axios.post(config.roleJurisdiction, params) //查詢角色角色权限
export const getSureJurisdiction = params => axios.post(config.sureJurisdiction, params) //确定授权
export const getUserAdmin = params => axios.post(config.userAdmin, params) //用户角色授权
export const getMenu = params => axios.post(config.menu, params) //获取权限全部菜单
export const getUserMenu = params => axios.post(config.userMenu, params) //获取全部菜单
export const getOrderBank = params => axios.post(config.orderBank, params) //绑定或解绑银行卡
export const getAccountsMoney = params => axios.post(config.accountsMoney, params) //账户余额
export const getAccountsBankNew = params => axios.post(config.bankNew, params) //银行卡信息
export const getAdminCode = params => axios.post(config.adminCode, params) //更换管理员验证码
export const getChangeAdmin = params => axios.post(config.changeAdmin, params) //更换管理员
export const getAccountsCode = params => axios.post(config.accountsCode, params) //银行卡验证码
export const getSearchSuperAdmin = params => axios.post(config.searchSuperAdmin, params) //查询超级管理员
export const adminDelete = params => axios.post(config.adminDelete, params) //批量删除管理员
export const getFileUpload = params => axios.post(`/third/api/huaWeiUpload/upload/img`, params) //上传图片

export const getCollegeNoticeList = params => axios.post(config.searchNoticeList, params) //公告列表
export const getCollegeNoticeListDel = params => axios.post(config.noticeListDelete, params) //公告列表删除
export const getCollegeNoticeListEdit = params => axios.post(config.noticeListEdit, params) //公告列表编辑
export const getCollegeNoticeListAdd = params => axios.post(config.noticeAdd, params) //公告列表添加
export const getCollegeBannerListDel = params => axios.post(config.bannerListDel, params) //轮播图列表
export const getCollegeBannerList = params => axios.post(config.bannerList, params) //轮播图列表刪除
export const getCollegeBannerType = params => axios.post(config.bannerType, params) //轮播图位置
export const getCollegeBannerEditSearch = params => axios.post(config.bannerEditSearch, params) //轮播图編輯查詢
export const getCollegeBannerEdit = params => axios.post(config.bannerListEdit, params) //轮播图編輯
export const getCollegeBannerAdd = params => axios.post(config.bannerAdd, params) //轮播图編輯
export const getAccountsSeat = params => axios.post(config.seat, params) //账户席位
export const getAccountsCharge = params => axios.post(config.charge, params) //升级企业详情
export const getAccountsUpdate = params => axios.post(config.versionUpdate, params) //升级企业支付
export const getEnterpriseRecharge = params => axios.post(config.enterpriseRecharge, params) //企业充值
