/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-05-17 08:36:40
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-01-07 11:39:32
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import '@/styles/index.scss' // glob scss
import VueClipboard from 'vue-clipboard2'
import VueCropper from 'vue-cropper'
import WebIM from './utils/im/WebIM'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.use(VueCropper)
Vue.use(VueClipboard)
Vue.config.productionTip = false
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)
new Vue({
  router,
  store,
  WebIM,
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
  render: h => h(App),
}).$mount('#app')
